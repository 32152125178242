import React from "react"
import HeroSection from "./heroSection"
import InteractiveMap from "./interactiveMap"
import cx from "classnames"
import styles from "./stylesheets/interactiveMapHeroSection.module.scss"

export default function InteractiveMapHeroSection({ title, description }) {
  return (
    <div className={styles.topLevelContainer}>
      <section
        className={cx(
          styles.bottomBorder,
          "row flex-wrap px-md-4 pl-md-5 pb-md-3",
        )}
      >
        <div className="col-12 col-xl-6 pt-md-5">
          <HeroSection
            title={title}
            description={description}
            pageType={null}
          />
        </div>
        <div className="d-none d-md-flex col-12 col-xl-6 justify-self-center px-0">
          <InteractiveMap />
        </div>
      </section>
    </div>
  )
}
