import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import styles from "./stylesheets/serviceBlurb.module.scss"
import cx from "classnames"

export default function ServiceBlurb({ iconData, description }) {
  const verticalAlignmentClass =
    description.length > 1 ? "align-items-start" : "align-items-center"
  return (
    <div
      key={iconData.url}
      className={cx(verticalAlignmentClass, "d-flex my-4")}
    >
      <div className="mr-3">
        <img
          className={styles.sectionBlurbImage}
          src={iconData.url}
          alt={iconData.alt}
        />
      </div>
      <div className={cx(styles.sectionBlurbText, "mr-4")}>
        <RichText render={description} linkResolver={linkResolver} />
      </div>
    </div>
  )
}
