import React from "react"
import Imgix from "react-imgix"
import styles from "./stylesheets/twoColumnImages.module.scss"
import cx from "classnames"

export default function TwoColumnImages({ leftImageData, rightImageData }) {
  return (
    <section className="d-flex flex-column flex-md-row overflow-hidden">
      <div className="w-100 w-md-50">
        <Imgix
          className={cx(styles.columnImage, "h-100 w-100")}
          src={leftImageData.url}
          alt={leftImageData.alt}
          sizes="50vw"
        />
      </div>
      <div className="w-100 w-md-50">
        <Imgix
          className={cx(styles.columnImage, "h-100 w-100")}
          src={rightImageData.url}
          alt={rightImageData.alt}
          sizes="50vw"
        />
      </div>
    </section>
  )
}
