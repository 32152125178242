import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceSection from "../components/serviceSection"
import SectionDivider from "../components/sectionDivider"
import VehicleSectionsHeader from "../components/vehicleSectionsHeader"
import InteractiveMapHeroSection from "../components/interactiveMapHeroSection"
import HeroSectionWithImage from "../components/heroSectionWithImage"
import FullWidthVideo from "../components/fullWidthVideo"
import TwoColumnBlurbSection from "../components/twoColumnBlurbsSection"
import TwoColumnImages from "../components/twoColumnImages"
import FullWidthImage from "../components/fullWidthImage"
import FullWidthText from "../components/fullWidthText"
import { standardizePrismicType } from "../utils/standardizePrismicType"

export const query = graphql`
  query VehicleMovesQuery {
    prismic {
      allVehicle_movess {
        edges {
          node {
            vehicle_move_meta_title
            vehicle_move_meta_description
            _meta {
              uid
            }
            body {
              ... on PRISMIC_Vehicle_movesBodyFull_width_image {
                primary {
                  image
                }
                label
              }
              ... on PRISMIC_Vehicle_movesBodyService_info {
                primary {
                  call_to_action_button_text
                  service_description
                  service_title
                  service_background_color
                  title_side
                  service_caption
                  call_to_action_button_background
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  lead_source
                }
                fields {
                  united_road_advantage_description
                  united_road_advantage_icon
                }
              }
              ... on PRISMIC_Vehicle_movesBodyInteractive_map {
                primary {
                  page_title
                  page_description
                }
              }
              ... on PRISMIC_Vehicle_movesBodyHero_section {
                primary {
                  page_title
                  page_tagline
                  hero_image
                  hero_description
                  salesforce_lead_source
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  call_to_action_button_text
                  subaction_destination_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  subaction_text
                }
              }
              ... on PRISMIC_Vehicle_movesBodyTwo_column_w__icons {
                fields {
                  service_column
                  service_icon
                  service_item
                }
                primary {
                  call_to_action_button_text
                  contact_form_hide_number_of_vehicles
                  contact_form_hide_company
                  contact_form_hide_advanced_fields
                  subaction_text
                  lead_source
                  subaction_destination_url {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Vehicle_movesBody50_50_images {
                primary {
                  left_image
                  right_image
                }
              }
              ... on PRISMIC_Vehicle_movesBodyHero_video {
                primary {
                  auto_play
                  hero_video
                  loop
                  sound
                }
              }
              ... on PRISMIC_Vehicle_movesBodyFull_width_text {
                primary {
                  rich_text
                }
              }
              ... on PRISMIC_Vehicle_movesBodyGrey_border {
                type
              }
            }
          }
        }
      }
    }
  }
`

const VehicleMove = ({ data }) => {
  const navigateToSection = (sectionId) => {
    setActiveSection(sectionId)
    const el = document.getElementById(sectionId)
    el && el.scrollIntoView({ behavior: "smooth" })
  }

  React.useEffect(() => {
    const startSectionId = window.location.href.split("#")[1]
    if (!activeSection && startSectionId) {
      navigateToSection(startSectionId)
      if (window.history.pushState) {
        window.history.pushState("", "", window.location.href.split("#")[0])
      }
    }
  })

  const [activeSection, setActiveSection] = React.useState(null)

  if (typeof window === `undefined`) return null
  const vehicleMovePages = data.prismic.allVehicle_movess.edges
  const page = vehicleMovePages.find((p) => {
    return p.node._meta.uid === window.location.pathname.split("/")[1]
  })
  if (!page) return null
  const prismicSections = page.node.body.map((section) =>
    standardizePrismicType(section),
  )
  if (!prismicSections) return null

  const metaTitle =
    page.node.vehicle_move_meta_title &&
    page.node.vehicle_move_meta_title[0]?.text
  const metaDescription =
    page.node.vehicle_move_meta_description &&
    page.node.vehicle_move_meta_description[0]?.text

  return (
    <Layout activePage={page.node._meta.uid} bottomBorder={true}>
      <SEO metaTitle={metaTitle} metaDescription={metaDescription} />
      <div>
        {prismicSections.map((prismicSection) => {
          let template
          switch (prismicSection.__typename) {
            case "PRISMIC_Vehicle_movesBodyRemarketed_header":
              const headerSections = prismicSections.filter(
                (section) =>
                  section.__typename ===
                  "PRISMIC_Vehicle_movesBodyService_info",
              )

              template = (
                <VehicleSectionsHeader
                  sectionTitles={headerSections.map(
                    (s) =>
                      s.primary.service_title[0] &&
                      s.primary.service_title[0].text,
                  )}
                  activeSection={activeSection}
                  navigateToSection={navigateToSection}
                />
              )
              break
            case "PRISMIC_Vehicle_movesBodyInteractive_map":
              template = (
                <InteractiveMapHeroSection
                  title={
                    prismicSection.primary.page_title[0] &&
                    prismicSection.primary.page_title[0].text
                  }
                  description={prismicSection.primary.page_description}
                />
              )
              break
            case "PRISMIC_Vehicle_movesBodyGrey_border":
              template = <SectionDivider />
              break
            case "PRISMIC_Vehicle_movesBodyService_info":
              const title =
                prismicSection.primary.service_title[0] &&
                prismicSection.primary.service_title[0].text
              template = (
                <ServiceSection
                  title={title}
                  description={prismicSection.primary.service_description}
                  backgroundColor={
                    prismicSection.primary.service_background_color
                  }
                  titleSide={prismicSection.primary.title_side}
                  callToActionText={
                    prismicSection.primary.call_to_action_button_text[0] &&
                    prismicSection.primary.call_to_action_button_text[0].text
                  }
                  leadSource={
                    prismicSection.primary.lead_source &&
                    prismicSection.primary.lead_source[0]?.text
                  }
                  callToActionBackgroundColor={
                    prismicSection.primary.call_to_action_button_background
                  }
                  serviceTagline={prismicSection.primary.service_caption}
                  serviceBlurbs={prismicSection.fields}
                  activeSection={activeSection}
                  setActiveSection={setActiveSection}
                  hideAdvanced={
                    prismicSection.primary.contact_form_hide_advanced_fields
                  }
                  hideCompany={prismicSection.primary.contact_form_hide_company}
                  hideNumberOfVehicles={
                    prismicSection.primary.contact_form_hide_number_of_vehicles
                  }
                />
              )
              break
            case "PRISMIC_Vehicle_movesBodyFull_width_image":
              template = (
                <FullWidthImage
                  src={prismicSection.primary.image.url}
                  alt={prismicSection.primary.image.alt}
                />
              )
              break
            case "PRISMIC_Vehicle_movesBodyHero_section":
              template = (
                <HeroSectionWithImage heroData={prismicSection.primary} />
              )
              break
            case "PRISMIC_Vehicle_movesBodyTwo_column_w__icons":
              template = (
                <TwoColumnBlurbSection
                  blurbsData={prismicSection.fields}
                  callToActionData={prismicSection.primary}
                />
              )
              break
            case "PRISMIC_Vehicle_movesBody50_50_images":
              template = (
                <TwoColumnImages
                  leftImageData={prismicSection.primary.left_image}
                  rightImageData={prismicSection.primary.right_image}
                />
              )
              break
            case "PRISMIC_Vehicle_movesBodyHero_video":
              template = <FullWidthVideo videoData={prismicSection.primary} />
              break
            case "PRISMIC_Vehicle_movesBodyFull_width_text":
              template = (
                <FullWidthText richText={prismicSection.primary.rich_text} />
              )
              break
            default:
              template = null
          }
          return template
        })}
      </div>
    </Layout>
  )
}

export default VehicleMove
