import React from "react"
import ServiceBlurb from "./serviceBlurb"
import ContactModal from "./contactModal"
import { Button } from "react-bootstrap"
import styles from "./stylesheets/twoColumnBlurbsSection.module.scss"
import cx from "classnames"

export default function TwoColumnBlurbsSection({
  blurbsData,
  callToActionData,
}) {
  const [showContactModal, setShowContactModal] = React.useState(false)
  const leftColumnBlurbs = blurbsData.filter(
    (blurb) => blurb.service_column === "Left",
  )
  const rightColumnBlurbs = blurbsData.filter(
    (blurb) => blurb.service_column === "Right",
  )
  const leadSource =
    callToActionData.lead_source && callToActionData.lead_source[0]?.text
  const callToActionText =
    callToActionData.call_to_action_button_text &&
    callToActionData.call_to_action_button_text[0]?.text

  return (
    <div className={cx(styles.topLevelContainer, "py-md-4 px-md-5")}>
      <div className={cx(styles.sectionTitle, "col-12 px-md-0")}>
        The United Road Advantage:
      </div>
      <div className="d-flex flex-column flex-md-row">
        <div className="col-md-6 col-12 pl-md-0 pr-md-5">
          {leftColumnBlurbs.map((blurb) => {
            return (
              <ServiceBlurb
                key={blurb.service_icon.url}
                iconData={blurb.service_icon}
                description={blurb.service_item}
              />
            )
          })}
          {callToActionText && (
            <div className="d-none d-md-flex align-items-center justify-content-start">
              <CallToActionButton
                callToAction={callToActionText}
                subActionText={
                  callToActionData.subaction_text &&
                  callToActionData.subaction_text[0]?.text
                }
                setShowContactModal={setShowContactModal}
                subActionUrl={
                  callToActionData.subaction_destination_url &&
                  callToActionData.subaction_destination_url.url
                }
              />
            </div>
          )}
        </div>
        <div className="col-md-6 col-12 px-md-0">
          {rightColumnBlurbs.map((blurb) => {
            return (
              <ServiceBlurb
                key={blurb.service_icon.url}
                iconData={blurb.service_icon}
                description={blurb.service_item}
              />
            )
          })}
        </div>
        {callToActionData.call_to_action_button_text && (
          <div className="d-flex d-md-none align-items-center justify-content-center">
            <CallToActionButton
              callToAction={
                callToActionData.call_to_action_button_text[0] &&
                callToActionData.call_to_action_button_text[0].text
              }
              subActionText={
                callToActionData.subaction_text &&
                callToActionData.subaction_text[0]?.text
              }
              setShowContactModal={setShowContactModal}
              subActionUrl={
                callToActionData.subaction_destination_url &&
                callToActionData.subaction_destination_url.url
              }
            />
            <ContactModal
              show={showContactModal}
              setShow={setShowContactModal}
              hideAdvanced={callToActionData.contact_form_hide_advanced_fields}
              hideNumberOfVehicles={
                callToActionData.contact_form_hide_number_of_vehicles
              }
              hideCompany={callToActionData.contact_form_hide_company}
              leadSource={leadSource}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function CallToActionButton({
  callToAction,
  subActionText,
  subActionUrl,
  setShowContactModal,
}) {
  if (!callToAction) {
    return null
  }

  return (
    <div className="d-flex flex-column">
      <Button
        onClick={() => setShowContactModal(true)}
        className={cx(
          styles.sectionCtaButton,
          !subActionText && "mb-4 mb-md-5",
          "btn text-white px-5 py-3",
        )}
      >
        {callToAction}
      </Button>
      {subActionText && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={subActionUrl}
          className={cx(styles.subactionLink, "my-3")}
        >
          {subActionText}
        </a>
      )}
    </div>
  )
}
