import cx from "classnames"
import { RichText } from "prismic-reactjs"
import React from "react"
import { Button } from "react-bootstrap"
import VisibilitySensor from "react-visibility-sensor"
import { linkResolver } from "../utils/linkResolver"
import { titleToAnchorId } from "../utils/titleToAnchorId"
import ContactModal from "./contactModal"
import ServiceBlurb from "./serviceBlurb"
import styles from "./stylesheets/serviceSection.module.scss"

export default function ServiceSection({
  title,
  description,
  callToActionText,
  serviceBlurbs,
  backgroundColor,
  titleSide,
  serviceTagline,
  activeSection,
  setActiveSection,
  callToActionBackgroundColor,
  hideAdvanced,
  hideCompany,
  hideNumberOfVehicles,
  leadSource,
}) {
  const [showContactModal, setShowContactModal] = React.useState(false)
  const backgroundImageClass =
    backgroundColor === "Gray" ? styles.backgroundGray : ""
  const ctaBackgroundClass =
    callToActionBackgroundColor === "Black" ? "bg-black" : "bg-blue"
  const orderingClass = titleSide === "Right" ? "flex-md-row-reverse" : ""
  const sectionId = titleToAnchorId(title)
  const displaySectionClass = activeSection === sectionId ? "" : "d-none"
  const toggleActiveSection = (id) => {
    if (activeSection === id) {
      setActiveSection(null)
    } else {
      setActiveSection(id)
    }
  }

  return (
    <VisibilitySensor
      onChange={(isEnterEvent) => {
        if (isEnterEvent) {
          setActiveSection(sectionId)
        }
      }}
      minTopValue={400}
      offset="{ top: 0 }"
      partialVisibility={true}
    >
      <section id={sectionId} className={styles.topLevelContainer}>
        <div
          className={cx(
            styles.serviceBannerMobile,
            "d-md-none border-right-0 border-left-0",
          )}
        >
          <button
            className={cx(
              "d-flex align-items-center justify-content-start font-weight-bold px-3 py-3 w-100 bg-white border-0",
            )}
            onClick={() => toggleActiveSection(sectionId)}
          >
            {title}
          </button>
        </div>
        <div
          className={cx(
            "d-md-flex flex-column pt-2 pt-md-5 px-3 px-lg-5",
            displaySectionClass,
            backgroundImageClass,
          )}
        >
          <div className={cx("d-flex flex-wrap pt-md-4", orderingClass)}>
            <div className="col-12 col-md-6 px-0 pr-md-5 pl-md-0">
              <div
                className={cx(
                  styles.sectionTitle,
                  "mt-2 mt-md-0 mb-3 font-weight-bold",
                )}
              >
                {title}
              </div>
              <div className={cx(styles.sectionDescription, "pr-4")}>
                <RichText render={description} linkResolver={linkResolver} />
              </div>
              {callToActionText && (
                <div className="d-none d-md-flex">
                  <Button
                    className={cx(
                      styles.sectionCtaButton,
                      ctaBackgroundClass,
                      "btn text-white px-5 py-3",
                    )}
                    onClick={() => setShowContactModal(true)}
                  >
                    {callToActionText}
                  </Button>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 py-4 py-md-0 px-0 pr-md-3 pl-md-0">
              <div className={styles.sectionBulletsTitle}>
                The United Road Advantage
              </div>
              <div>
                {serviceBlurbs.map((blurb) => {
                  return (
                    <ServiceBlurb
                      key={blurb.united_road_advantage_icon.url}
                      iconData={blurb.united_road_advantage_icon}
                      description={blurb.united_road_advantage_description}
                    />
                  )
                })}
              </div>
              {callToActionText && (
                <div className="d-flex d-md-none justify-content-center">
                  <Button
                    className={cx(
                      styles.sectionCtaButton,
                      ctaBackgroundClass,
                      "btn text-white px-5 py-3",
                    )}
                    onClick={() => setShowContactModal(true)}
                  >
                    {callToActionText}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {serviceTagline !== "" && (
            <div className="d-flex justify-content-center text-center mb-md-5">
              <RichText render={serviceTagline} linkResolver={linkResolver} />
            </div>
          )}
        </div>
        <ContactModal
          show={showContactModal}
          setShow={setShowContactModal}
          hideAdvanced={hideAdvanced}
          hideCompany={hideCompany}
          hideNumberOfVehicles={hideNumberOfVehicles}
          leadSource={leadSource}
        />
      </section>
    </VisibilitySensor>
  )
}
