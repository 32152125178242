import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import cx from "classnames"
import styles from "./stylesheets/fullWidthText.module.scss"

export default function FullWidthText({ richText, noPadding }) {
  return (
    <section className={cx(!noPadding && "py-2 py-md-3")}>
      <div className={cx(styles.richText, !noPadding && "px-md-5", "px-3")}>
        <RichText render={richText} linkResolver={linkResolver} />
      </div>
    </section>
  )
}
