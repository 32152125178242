import React from "react"
import { titleToAnchorId } from "../utils/titleToAnchorId"
import styles from "./stylesheets/vehicleSections.module.scss"
import cx from "classnames"

export default function VehicleSectionsHeader({
  sectionTitles,
  activeSection,
  navigateToSection,
}) {
  return (
    <div
      className={cx(
        styles.vehicleSectionTopLevel,
        "fixed-top position-sticky bg-dark",
      )}
    >
      <div className={styles.topLevelContainer}>
        <nav className="d-flex justify-content-between align-items-center w-100 py-3 px-5 mt-4">
          {sectionTitles.map((sectionName) => {
            const sectionId = titleToAnchorId(sectionName)
            const activeClass = sectionId === activeSection ? "text-white" : ""

            return (
              <button
                key={sectionName}
                onClick={() => navigateToSection(sectionId)}
                className={cx(styles.sectionHeader, activeClass, "px-0")}
              >
                {sectionName}
              </button>
            )
          })}
        </nav>
      </div>
    </div>
  )
}
